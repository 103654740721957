$context-color: (
  "dashboard": #F74900,
  "electricity": #F74900,
  "gas": #FFB700,
  "water": #00A4E0,
  "temperature": #00AAAA,
  "co2": #00AAAA,
  "humidity": #00AAAA,
  "plugsense": #F74900,
  "display": #000000,
);

$context-color-previous: (
  "dashboard": #FF8E5E,
  "electricity": #FF8E5E,
  "gas": #FFE020,
  "water": #00C8FF,
  "temperature": #66CCCC,
  "co2": #66CCCC,
  "humidity": #66CCCC,
  "plugsense": #FF8E5E,
  "display": #000000,
);

$medium: (
  "dashboard": $emicon-l,
  "electricity": $emicon-e,
  "gas": $emicon-g,
  "water": $emicon-w,
  "temperature": $emicon-t,
  "co2": $emicon-f,
  "humidity": $emicon-h,
  "plugsense": $emicon-c,
);

$medium-active: (
  "dashboard": $emicon-l,
  "electricity": $emicon-e2,
  "gas": $emicon-g2,
  "water": $emicon-w2,
  "temperature": $emicon-t2,
  "co2": $emicon-f2,
  "humidity": $emicon-h2,
  "plugsense": $emicon-c2,
);


$gray60: #666666 !default;
$gray40: #989898 !default;
$gray30: #B0B0B0 !default;
$gray20: #CBCBCB !default;
$gray10: #E4E4E4 !default;

$weight-light:       300;
$weight-regular:     400;
$weight-semibold:    700;
$weight-bold:        700;
$weight-black:       900;

$custom-brand-primary: #E35205;

// This is sort of a grid width used inside the charts
// Margins are multiples of this value
// Make sure this is in sync with the value inside charts/base.coffee
$chart-internal-square: 12px;

@function color($key) {
  @if map-has-key($context-color, $key) {
    @return map-get($context-color, $key);
  }

  @warn "Unknown `#{$key}` in $context-color.";
  @return null;
}

@function color-previous($key) {
  @if map-has-key($context-color-previous, $key) {
    @return map-get($context-color-previous, $key);
  }

  @warn "Unknown `#{$key}` in $context-color-previous.";
  @return null;
}

@function medium($key) {
  @if map-has-key($medium, $key) {
    @return map-get($medium, $key);
  }

  @warn "Unknown `#{$key}` in $medium.";
  @return null;
}

@function medium-active($key) {
  @if map-has-key($medium-active, $key) {
    @return map-get($medium-active, $key);
  }

  @warn "Unknown `#{$key}` in $medium-active.";
  @return null;
}
